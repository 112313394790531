import type { GqlFieldConfig } from "../FieldConfig.tsx";

export const sizeBytesGbField = {
    render: SizeBytesGb,
    align: "text-right",
} satisfies Partial<GqlFieldConfig<any, any, any>>;

export function SizeBytesGb({ value }: { value: number }) {
    const sizeGB = (value / 1024 / 1024 / 1024).toFixed(2);
    return <div>{sizeGB} GB</div>;
}
