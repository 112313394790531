import { todoAction } from "../../dev/todoSubmit.ts";
import { AdminLinkButton } from "../AdminLinkButton.tsx";
import { Extra } from "../AdminTable.tsx";
import { gql } from "../graphql.gen/gql.ts";
import type { BillingAccount } from "../graphql.gen/graphql.ts";

export function BillingAccountBlock({ value }: { value: Pick<BillingAccount, "title" | "email" | "id"> }) {
    return (
        <AdminLinkButton
            action={todoAction}
            label={
                <Extra className="max-w-300px text-ellipsis overflow-x-hidden" value={value.title}>
                    {value.email}
                </Extra>
            }
        />
    );
}

BillingAccountBlock.fragment = gql(/* GraphQL */ `
    fragment BillingAccountBlock on BillingAccount {
        title
        email
        id
    }
`);
