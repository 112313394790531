import { useQuery } from "@apollo/client";
import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import { todoAction } from "../../dev/todoSubmit.ts";
import { BillingAccountBlock } from "../accounts/BillingAccountBlock.tsx";
import { AdminLinkButton } from "../AdminLinkButton.tsx";
import { gf, type GqlFieldsOf } from "../FieldConfig.tsx";
import { TextFilter, UnixDateRangeFilter } from "../filters.tsx";
import { gql } from "../graphql.gen/gql.ts";
import { type GetServicePackageListQuery, OrderDirection, ServicePackageOrderFields } from "../graphql.gen/graphql.ts";
import { extractData } from "../graphql/extractData.tsx";
import { GraphqlTable } from "../GraphqlTable.tsx";
import { baIdField, locationField, userIdField } from "../table_fields/standardFields.tsx";
import { whenNotNull } from "../table_fields/whenNotNull.tsx";
import { ServiceOs } from "./ServiceOs.tsx";
import { type ServiceStatus, ServiceStatusBadge, ServiceStatusFilter } from "./ServiceStatus.tsx";

const GET_SERVICEPACKAGE_LIST = gql(/* GraphQL */ `
    query GetServicePackageList(
        $limit: Int
        $page: Int
        $orderField: ServicePackageOrderFields
        $orderDir: OrderDirection
        $search: String
        $filters: [ServicePackageFilter!]
    ) {
        admin_servicepackage_list(
            limit: $limit
            page: $page
            orderField: $orderField
            orderDir: $orderDir
            search: $search
            filters: $filters
        ) {
            items {
                uuid
                user_id
                display_name
                billing_account_id
                is_multi_node
                service
                status
                version
                created_at
                updated_at
                is_deleted
                resources {
                    resource_id
                    resource_location
                    resource_type
                }
                properties {
                    service_ip
                }
                ip_address_object {
                    address
                }
                whitelist_addresses
                billing_account {
                    ...BillingAccountBlock
                }
                _location
            }
            paging {
                total
            }
        }
    }
`);

export type GQServicePackageItem = NonNullable<
    GetServicePackageListQuery["admin_servicepackage_list"]["items"]
>[number];

function VmsCount({ item }: { item: GQServicePackageItem }) {
    const count = item?.resources ? item.resources.filter((vm) => vm.resource_type === "vm").length : 0;

    return <AdminLinkButton isMissing={!count} action={todoAction} label={<span>{count} VM(s)</span>} />;
}

const ServicePackageFields: GqlFieldsOf<GQServicePackageItem, ServicePackageOrderFields> = [
    gf({
        ...userIdField,
        get: (a) => a.user_id,
        order: ServicePackageOrderFields.UserId,
    }),
    gf({
        ...baIdField,
        get: (a) => a.billing_account_id,
        order: ServicePackageOrderFields.BillingAccountId,
    }),
    gf({
        id: "billing_account",
        title: "Billing Account",
        get: (a) => a.billing_account,
        render: whenNotNull(BillingAccountBlock),
    }),
    gf({
        id: "uuid",
        title: "UUID",
        get: (a) => a.uuid,
        copyable: true,
        order: ServicePackageOrderFields.Uuid,
        filter: TextFilter,
    }),
    gf({
        id: "created",
        title: "Created At",
        get: (a) => (a.created_at ? apiUnixToDate(a.created_at as number) : undefined),
        order: ServicePackageOrderFields.CreatedAt,
        filter: UnixDateRangeFilter,
    }),
    gf({
        id: "service",
        title: "Service",
        get: (a) => a.service,
        render: ServiceOs,
        order: ServicePackageOrderFields.Service,
        filter: TextFilter,
    }),
    gf({
        id: "display_name",
        title: "Service Name",
        get: (a) => a.display_name,
        order: ServicePackageOrderFields.DisplayName,
        filter: TextFilter,
    }),
    gf({
        id: "address",
        title: "Public IP",
        get: (a) => a.ip_address_object?.address,
        copyable: true,
    }),
    gf({
        id: "service_ip",
        title: "Service IP",
        get: (a) => a.properties?.service_ip,
        copyable: true,
    }),
    gf({
        id: "whitelist_addresses",
        title: "Whitelist\nAddresses",
        get: (a) => a.whitelist_addresses,
    }),
    gf({
        id: "vms",
        title: "VMs",
        get: (a) => a,
        render: VmsCount,
    }),
    gf({
        ...locationField,
        get: (a) => a._location,
    }),
    gf({
        id: "status",
        title: "Status",
        get: (a) => a.status as ServiceStatus,
        order: ServicePackageOrderFields.Status,
        render: ServiceStatusBadge,
        filter: ServiceStatusFilter,
    }),
];

export function ServicePackagesTable() {
    return (
        <GraphqlTable
            title="Service Packages"
            fields={ServicePackageFields}
            defaults={{ orderField: ServicePackageOrderFields.CreatedAt, orderDir: OrderDirection.Desc }}
            getId={(item) => item.uuid}
            useQuery={(variables) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const q = useQuery(GET_SERVICEPACKAGE_LIST, { variables });
                return extractData(q, (d) => d.admin_servicepackage_list);
            }}
        />
    );
}
