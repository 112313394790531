import type { ManagedServiceEnum } from "@warrenio/api-spec/spec.oats.gen";
import { ServiceOsImageIcon } from "../../modules/services/os/ServiceOsImageIcon.tsx";
import { useServiceOs } from "../../modules/services/os/useServiceOs.ts";
import type { GQServicePackageItem } from "./ServicePackagesTable.tsx";

export function ServiceOs({ item }: { item: GQServicePackageItem }) {
    const { service, version } = item;
    const { title } = useServiceOs({ os_name: service as ManagedServiceEnum, os_version: version });

    return (
        <div className="HStack gap-0.25rem whitespace-nowrap">
            <ServiceOsImageIcon os_name={service as ManagedServiceEnum} className="color-primary size-0.875rem" />
            {title}
        </div>
    );
}
