import { keys } from "remeda";
import { Badge, type BadgeProps } from "../../components/Badge.tsx";
import { EnumComponentFilter } from "../filters.tsx";

export type ServiceStatus = "deleted" | "requested" | "active";

const serviceStatusColors: Record<ServiceStatus, BadgeProps["color"]> = {
    deleted: "error",
    requested: "warning",
    active: "success",
};

export function ServiceStatusBadge({ value }: { value: ServiceStatus }) {
    return <Badge color={serviceStatusColors[value]}>{value}</Badge>;
}

export function ServiceStatusFilter() {
    return <EnumComponentFilter values={keys(serviceStatusColors)} component={ServiceStatusBadge} />;
}
